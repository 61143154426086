import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import lspTSX from '../Cache/lspTSX.json';
import lspTSXV from '../Cache/lspTSXV.json';
import lspCSE from '../Cache/lspCSE.json';
import { useEffect } from "react";

function Lsp() {

    const pU = process.env.PUBLIC_URL;

    useEffect(() => {

        document.title = 'Largest short positions - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Largest short positions - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/largest-short-positions';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/largest-short-positions');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');
        

        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-lsp page-template-template-lsp-php page page-id-69 with-sidebar';

        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="lspextra">
                <div id="container">
                <div id="page-wrap">
                    <noscript>
                        <div id="js-disabled">
                            Javascript is required for our website's full capabilities!				
                        </div>
                    </noscript>
                    <Header menuitem='lsp'/>
                    <div id="content">
                        <div id="inner-content" className="wrap">
                            <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                
                                <article id="post-69" className="cf post-69 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                    <section className="entry-content" itemProp="articleBody">
                                        <div className="content-wrap">
                                            <div id="lsp-data" className="page-data">
                                                <div className="inner-page-data">
                                                    <div className="data-controls no-sep table-tab-controls">
                                                        <div className="left-data-controls">
                                                            <button className="option sectors-charts active" data-owns="tsx" data-hovb="TSX">TSX</button>
                                                            <button className="option sectors-charts" data-owns="tsxv" data-hovb="TSX VENTURE">TSX VENTURE</button>
                                                            <button className="option sectors-charts" data-owns="cse" data-hovb="CSE">CSE</button>
                                                        </div>
                                                    </div>
                                                    <div className="data-table-wrapper data-table">
                                                        <div className="data-controls">
                                                            <div className="right-data-controls">
                                                                <button className="option">
                                                                    <div className="description">Largest Short Positions</div>
                                                                    <div className="sub-description">{lspTSX['dataHeadingTo']}</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller active" data-type="tsx">
                                                            <div id="lsp-tsx-lsp-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSX['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSX['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% TOTAL TRADED VALUE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspTSX['lsp']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspTSX['lsp'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lsp'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lsp'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lsp'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lsp'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lsp'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspTSX['lsp'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{lspTSX['lsp'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                        <div className="table-cell"><span className={lspTSX['lsp'][stemp].per_change >= 0 ? "col-content no-arrow-mobile no-color up" : "col-content no-arrow-mobile no-color down"}>{lspTSX['lsp'][stemp].per_total_traded_value.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller " data-type="tsxv">
                                                            <div id="lsp-tsxv-lsp-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSXV['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSXV['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% TOTAL TRADED VALUE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspTSXV['lsp']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspTSXV['lsp'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lsp'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lsp'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lsp'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lsp'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lsp'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspTSXV['lsp'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{lspTSXV['lsp'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                        <div className="table-cell"><span className={lspTSXV['lsp'][stemp].per_change >= 0 ? "col-content no-arrow-mobile no-color up" : "col-content no-arrow-mobile no-color down"}>{lspTSXV['lsp'][stemp].per_total_traded_value.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller " data-type="cse">
                                                            <div id="lsp-cse-lsp-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspCSE['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspCSE['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% TOTAL TRADED VALUE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspCSE['lsp']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspCSE['lsp'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lsp'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lsp'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lsp'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lsp'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lsp'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspCSE['lsp'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{lspCSE['lsp'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                        <div className="table-cell"><span className={lspCSE['lsp'][stemp].per_change >= 0 ? "col-content no-arrow-mobile no-color up" : "col-content no-arrow-mobile no-color down"}>{lspCSE['lsp'][stemp].per_total_traded_value.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-table-wrapper data-table">
                                                        <div className="data-controls">
                                                            <div className="right-data-controls">
                                                                <button className="option">
                                                                    <div className="description">Largest Net Increases</div>
                                                                    <div className="sub-description">{lspTSX['dataHeadingTo']}</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller active" data-type="tsx">
                                                            <div id="lsp-tsx-lni-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSX['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSX['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspTSX['lni']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspTSX['lni'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lni'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lni'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lni'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lni'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lni'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspTSX['lni'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{lspTSX['lni'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )
                                                                }                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller " data-type="tsxv">
                                                            <div id="lsp-tsxv-lni-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSXV['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSXV['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspTSXV['lni']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspTSXV['lni'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lni'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lni'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lni'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lni'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lni'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspTSXV['lni'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{lspTSXV['lni'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller " data-type="cse">
                                                            <div id="lsp-cse-lni-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspCSE['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspCSE['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspCSE['lni']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspCSE['lni'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lni'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lni'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lni'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lni'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lni'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspCSE['lni'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{lspCSE['lni'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="data-table-wrapper data-table">
                                                        <div className="data-controls">
                                                            <div className="right-data-controls">
                                                                <button className="option">
                                                                    <div className="description">Largest Percent Increases</div>
                                                                    <div className="sub-description">{lspTSX['dataHeadingTo']}</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller active" data-type="tsx">
                                                            <div id="lsp-tsx-lpi-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSX['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSX['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspTSX['lpi']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspTSX['lpi'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lpi'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lpi'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lpi'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSX['lpi'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspTSX['lpi'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{lspTSX['lpi'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )                                                                
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller " data-type="tsxv">
                                                            <div id="lsp-tsxv-lpi-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSXV['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspTSXV['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspTSXV['lpi']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspTSXV['lpi'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lpi'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lpi'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lpi'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspTSXV['lpi'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspTSXV['lpi'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{lspTSXV['lpi'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )                                                                
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="table-controller " data-type="cse">
                                                            <div id="lsp-cse-lpi-table" className="responsive-table lsp-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                    <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspCSE['tableHeadingTo']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{lspCSE['tableHeadingFrom']}</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                    Object.keys(lspCSE['lpi']).map((stemp, index) => 
                                                                    <a key={index} className="row" href={pU+"/stock/"+lspCSE['lpi'][stemp].security}>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lpi'][stemp].symbol}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lpi'][stemp].company_name}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lpi'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{lspCSE['lpi'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={lspCSE['lpi'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{lspCSE['lpi'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                    </a>
                                                                    )                                                                
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tables-legend">SHORT POSITION DATA ANALYSIS PROVIDED BY HARRIS MARKET CONSULTING BASED ON REPORTED DATA TO IRROC BY EACH INDIVIDUAL MARKETPLACE WHERE TRADES WERE MARKED AS SHORT SALES. SHORT POSITIONS AS OF {lspTSX['tableHeadingTo']} BASED ON AGGREGATE CHANGES FROM {lspTSX['tableHeadingFrom']} REPORTING.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </article>
                            </main>
                            <Sidebar />
                        </div>
                    </div>
                </div>
                <Footer />
                <div id="overlay-for-mobile-menu"></div>
            </div>
            </div>
        </>
    );
}

export default Lsp;