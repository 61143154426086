import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import { useEffect } from "react";

function Terms() {

    const pU = process.env.PUBLIC_URL;

    useEffect(() => {

        document.title = 'Terms of Use - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Terms of Use - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/terms-of-use';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/terms-of-use');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-legals page-template-template-legals-php page page-id-77 with-sidebar';
        
        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="container">
                <div id="page-wrap">
                    <noscript>
                        <div id="js-disabled">
                            Javascript is required for our website's full capabilities!				
                        </div>
                    </noscript>
                    <Header />
                    <div id="content">
                        <div id="inner-content" className="wrap">
                            <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                <article id="post-77" className="cf post-77 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                    <header className="article-header">
                                        <h1 className="page-title" itemProp="headline">Terms of Use</h1>
                                    </header>
                                    <section className="entry-content cf" itemProp="articleBody">
                                        <h2 style={{"clear":"both"}}>Email Policy</h2>
                                        <p className="byline">HARRIS MARKET CONSULTING AND SHORTDATA.CA, its affiliates and third party providers are not responsible for any errors or omissions in the information contained on or accessed through this Site. All such information in this Site is provided “as is”. HARRIS MARKET CONSULTING AND SHORTDATA.CA, its affiliates and third party providers make no warranties or representations and disclaim all express, implied and statutory warranties of any kind to the user and any third party, including, but not limited to, any warranties of accuracy, timeliness, completeness, merchantability, non-infringement and fitness for any particular purpose.</p>
                                        <p className="byline">Except for claims that cannot be excluded by the applicable local law, HARRIS MARKET CONSULTING AND SHORTDATA.CA, its affiliates and third party providers shall not have any liability, including but not limited to, tort, contract or any other liability to user or any third party arising as a result of use of or access to this Site.</p>
                                        <p className="byline">HARRIS MARKET CONSULTING AND SHORTDATA.CA, its affiliates or third party providers shall not be liable to user or any third party for lost profits or lost opportunity, direct, indirect, special, consequential, incidental or punitive damages whatsoever, even if HARRIS MARKET CONSULTING AND SHORTDATA.CA, its affiliates or third party providers have been advised of the possibility of such damages.</p>
                                        <p className="byline">Some jurisdictions do not allow for the exclusion or limitation of implied warranties or liability for incidental or consequential damages. Therefore, the above limitations may not apply to you, or there may be applicable laws which supersede the above. Any clause of this disclaimer declared invalid by the appropriate authority in each jurisdiction shall be deemed severable and shall not affect the validity or enforceability of the remainder of this disclaimer. The terms of this disclaimer are governed by the laws of the Province of Ontario. HARRIS MARKET CONSULTING AND SHORTDATA.CA reserves the right to amend the document at its discretion at any time without notice.</p>
                                        <h2 style={{"clear":"both"}}>Advertising &amp; Banners</h2>
                                        <p className="byline">All material listed within Harris Market Consulting and SHORTDATA.CA were prepared by Harris Market Consulting and SHORTDATA.CA based upon information supplied by the companies, promoters, or from other sources believed to be reliable. The information contained on Harris Market Consulting and SHORTDATA.CA is not guaranteed by Harris Market Consulting and SHORTDATA.CA to be accurate and should not be considered to be all-inclusive.</p>
                                        <p className="byline">The companies and or promoters discussed on Harris Market Consulting and SHORTDATA.CA have not approved the statements made herein.</p>
                                        <p className="byline">Any discussions and pages may contain forward-looking statements that involve risks and uncertainties. A company’s actual results could differ materially from those described in any forward-looking statements or announcements mentioned on Harris Market Consulting and SHORTDATA.CA.</p>
                                        <p className="byline">All material is for informational purposes only and should not be construed as an offer or solicitation of an offer to buy or sell securities. Harris Market Consulting and SHORTDATA.CA is not a licensed broker, broker dealer, market maker, investment banker, investment advisor, analyst, or underwriter. There are risks involved. Always remember that Harris Market Consulting and SHORTDATA.CA is not an analyst and investing in securities such as the ones listed within are for high-risk tolerant individuals only and not the general public.</p>
                                        <p className="byline">Harris Market Consulting and SHORTDATA.CA lists promoters and companies for informational purposes only and does not recommend any promoter or company listed within. Harris Market Consulting and SHORTDATA.CA may receive a fee from promoters listed within for the listing only, not for promotion or advertising of any companies listed within. Harris Market Consulting and SHORTDATA.CA and its affiliates, officers, directors and employees intend to buy and sell shares in any company mentioned within and may profit in the event those shares rise in value. Whether or not you are an experienced investor you should always consult with a broker before purchasing or selling any securities viewed on https://www.SHORTDATA.CA and its affiliates, officers, directors and employees are also not securities lawyers so please consult a securities lawyer for an opinion on anything viewed within.</p>
                                        <p className="byline">Harris Market Consulting and SHORTDATA.CA have been compensated up to $1,500 for each banner ad appearing on this site. The content of banner ads is provided by the company and or produced from publicly available information on the company’s corporate website and subsequently designed into ads.</p>
                                    </section>
                                </article>
                            </main>
                            <Sidebar />
                        </div>
                    </div>
                </div>
                <Footer />
                <div id="overlay-for-mobile-menu"></div>
            </div>
        </>
    );
}

export default Terms;