import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import tcsTSX from '../Cache/tcsTSX.json';
import tcsTSXV from '../Cache/tcsTSXV.json';
import tcsCSE from '../Cache/tcsCSE.json';
import { useEffect } from "react";

function Tcs() {

    const pU = process.env.PUBLIC_URL;

    useEffect(() => {

        document.title = 'Top covered shorts - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Top covered shorts - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/top-covered-shorts';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/top-covered-shorts');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-tcs page-template-template-tcs-php page page-id-79 with-sidebar';

        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="tcsextra">
                <div id="container">
                    <div id="page-wrap">
                        <noscript>
                            <div id="js-disabled">
                                Javascript is required for our website's full capabilities!				
                            </div>
                        </noscript>
                        <Header menuitem='tcs'/>
                        <div id="content">
                            <div id="inner-content" className="wrap">
                                <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                    
                                    <article id="post-79" className="cf post-79 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                        <section className="entry-content" itemProp="articleBody">
                                            <div className="content-wrap">
                                                <div id="tcs-data" className="page-data">
                                                    <div className="inner-page-data">
                                                        <div className="data-controls no-sep table-tab-controls">
                                                            <div className="left-data-controls">
                                                                <button className="option active" data-owns="tsx" data-hovb="TSX">TSX</button>
                                                                <button className="option" data-owns="tsxv" data-hovb="TSX VENTURE">TSX VENTURE</button>
                                                                <button className="option" data-owns="cse" data-hovb="CSE">CSE</button>
                                                            </div>
                                                        </div>
                                                        <div className="data-table-wrapper data-table">
                                                            <div className="data-controls">
                                                                <div className="right-data-controls">
                                                                    <button className="option">
                                                                        <div className="description">Largest Net Decreases</div>
                                                                        <div className="sub-description">{tcsTSX['dataHeadingTo']}</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="table-controller active" data-type="tsx">
                                                                <div id="tcs-tsx-lnd-table" className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSX['lnd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSX['lnd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lnd'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSX['lnd'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{tcsTSX['lnd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-controller " data-type="tsxv">
                                                                <div id="tcs-tsxv-lnd-table" className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSXV['lnd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSXV['lnd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lnd'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSXV['lnd'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{tcsTSXV['lnd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-controller " data-type="cse">
                                                                <div id="tcs-cse-lnd-table" className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsCSE['lnd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsCSE['lnd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lnd'][stemp].net_change.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsCSE['lnd'][stemp].per_change >= 0 ? "col-content no-arrow-desktop no-color up" : "col-content no-arrow-desktop no-color down"}>{tcsCSE['lnd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }    
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="data-table-wrapper data-table">
                                                            <div className="data-controls">
                                                                <div className="right-data-controls">
                                                                    <button className="option">
                                                                        <div className="description">Largest Percent Decreases</div>
                                                                        <div className="sub-description">{tcsTSX['dataHeadingTo']}</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="table-controller active" data-type="tsx">
                                                                <div id="tcs-tsx-lpd-table" className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSX['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSX['lpd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSX['lpd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSX['lpd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSX['lpd'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{tcsTSX['lpd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-controller " data-type="tsxv">
                                                                <div id="tcs-tsxv-lpd-table" className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsTSXV['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsTSXV['lpd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsTSXV['lpd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsTSXV['lpd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsTSXV['lpd'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{tcsTSXV['lpd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="table-controller " data-type="cse">
                                                                <div id="tcs-cse-lpd-table" className="responsive-table tcs-table">
                                                                    <div className="table-head">
                                                                        <div className="table-cell"><span className="col-content">SYMBOL</span></div>
                                                                        <div className="table-cell"><span className="col-content">COMPANY NAME</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingTo']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{tcsCSE['tableHeadingFrom']}</span></div>
                                                                        <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                    </div>
                                                                    <div className="table-body">
                                                                    {
                                                                        Object.keys(tcsCSE['lpd']).map((stemp, index) => 
                                                                            <a key={index} className="row" href={pU+"/stock/"+tcsCSE['lpd'][stemp].security}>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].symbol}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].company_name}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].current_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className="col-content">{tcsCSE['lpd'][stemp].prev_short_traded_value.toLocaleString('en-US')}</span></div>
                                                                                <div className="table-cell"><span className={tcsCSE['lpd'][stemp].per_change >= 0 ? "col-content no-color up" : "col-content no-color down"}>{tcsCSE['lpd'][stemp].per_change.toLocaleString('en-US', {minimumFractionDigits: 2})}</span></div>
                                                                            </a>
                                                                        )
                                                                    }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tables-legend">SHORT POSITION DATA ANALYSIS PROVIDED BY HARRIS MARKET CONSULTING BASED ON REPORTED DATA TO IRROC BY EACH INDIVIDUAL MARKETPLACE WHERE TRADES WERE MARKED AS SHORT SALES. SHORT POSITIONS AS OF {tcsTSX['tableHeadingTo']} BASED ON AGGREGATE CHANGES FROM {tcsTSX['tableHeadingFrom']} REPORTING.</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </article>
                                </main>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <div id="overlay-for-mobile-menu"></div>
                </div>
            </div>
        </>
    );
}

export default Tcs;