function Sidebar() {
    return (
        <>
            <div className="main-sidebar cf" role="complementary">
                <div id="text-8" className="sidebar-symbol-search no-title small-top-margin fullwidth-widget widget widget_text">
                    <div className="widget-helper">
                        <h4 className="widgettitle"><span>STOCK SYMBOL SEARCH</span></h4>
                        <div className="textwidget">
                            <div id="stocksearchform-container">
                                <form role="search" method="get" id="stocksearchform" className="stocksearchform" onSubmit={()=>{javascript:void(0);}}>
                                    <input autoComplete="off" id="c" name="c" placeholder="SEARCH STOCK SYMBOL">
                                    </input>
                                    <label htmlFor="c" className="icon icon-search"></label>
                                    <input type="submit" className="stocksearchformbuttom" value="search" disabled />
                                </form>
                                <div className="results"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="text-9" className="sidebar-stocks widget widget_text">
                    <div className="widget-helper">
                        <h4 className="widgettitle"><span>INDEXES</span></h4>
                        <div className="textwidget">
                            <div className="inner-coinbar sidebar-wrap ax-loading">
                                <div id="sidebar-indexes" className="coins-enum">
                                    <div className="coins-header">
                                        <div>SYMBOL</div>
                                        <div>LAST PRICE</div>
                                        <div>CHANGE</div>
                                        <div>CHANGE %</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="text-10" className="sidebar-stocks widget widget_text">
                    <div className="widget-helper">
                        <h4 className="widgettitle"><span>TRENDING STOCKS</span></h4>
                        <div className="textwidget">
                            <div className="inner-coinbar sidebar-wrap ax-loading">
                                <div id="sidebar-trending" className="coins-enum">
                                    <div className="coins-header">
                                        <div>SYMBOL</div>
                                        <div>LAST PRICE</div>
                                        <div>CHANGE</div>
                                        <div>CHANGE %</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="text-11" className="sidebar-news no-bottom-margin-widget widget widget_text">
                    <div className="widget-helper">
                        <h4 className="widgettitle"><span>EXTERNAL NEWS FEED</span></h4>
                        <div className="textwidget">
                            <div className="sidebar-news-wrapper ax-loading">
                                <div id="sidebar-news" className="sidebar-news-enum">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;