
import { useState, useEffect } from "react";



function Test() {

        let [counter, changeCnt] = useState(0);

        useEffect(() => {
            
        }, [counter]);

        function increaseCnt() {

            changeCnt(counter + 1);
        }

        function decreaseCnt() {

            changeCnt(counter - 1);
        }

        return <>
                    <div>{counter}</div>
                    <br />
                    <div onClick={increaseCnt}>Inc</div>
                    <br />
                    <div onClick={decreaseCnt}>Dec</div>
                </>;
}

export default Test;