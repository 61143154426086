import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import { useEffect } from "react";

function NotFound() {

    const pU = process.env.PUBLIC_URL;

    useEffect(() => {

        document.title = 'Page not found - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Page not found - Shortdata.ca');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        document.getElementsByTagName('body')[0].className = 'error404 with-sidebar';
        
        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="container">
                <div id="page-wrap">
                    <noscript>
                        <div id="js-disabled">
                            Javascript is required for our website's full capabilities!				
                        </div>
                    </noscript>
                    <Header />
                    <div id="content">
                        <div id="inner-content" className="wrap">
                            <main id="main" className="m-all t-2of3 d-5of7 cf" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                <article id="post-not-found" className="hentry cf">
                                    <section className="entry-content">
                                        <div className="content-wrap">
                                            <h1><span className="hash">#</span>404 <span className="pnf">Page not found</span></h1>
                                            <p className="additional-info">No worries! If you got lost, you can use the Search Option or read the links below for some inspiration.</p>
                                            <div className="separator line blue"></div>
                                        </div>
                                    </section>
                                </article>
                            </main>
                            <Sidebar />
                        </div>
                    </div>
                </div>
                <Footer />
                <div id="overlay-for-mobile-menu"></div>
            </div>
        </>
    );
}

export default NotFound;