function Footer() {

    const pU = process.env.PUBLIC_URL;

    return (
        <>
            <footer className="footer" role="contentinfo" itemScope itemType="http://schema.org/WPFooter">
                <div id="inner-footer" className="wrap wrap-narrow wrap-padding">
                    <div id="foot-logo"><a href={pU+"/"} rel="nofollow">Shortdata.ca</a></div>
                    <div className="sidebar cf" role="complementary">
                        <div id="nav_menu-2" className="footer-network widget widget_nav_menu">
                            <div className="widget-helper">
                                <h4 className="widgettitle"><span>OUR NETWORK INCLUDES:</span></h4>
                                <div className="menu-network-menu-container">
                                    <ul id="menu-network-menu" className="menu">
                                        <li id="menu-item-115" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-115"><a target="_blank" rel="noopener" href="http://www.nakedshortreport.com" data-hovb="nakedshortreport.com">nakedshortreport.com</a></li>
                                        <li id="menu-item-116" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-116"><a target="_blank" rel="noopener" href="http://www.otcshortreport.com" data-hovb="otcshortreport.com">otcshortreport.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="nav_menu-3" className="footer-other footer-menu-no-title widget widget_nav_menu">
                            <div className="widget-helper">
                                <h4 className="widgettitle"><span>FOOTER MENU</span></h4>
                                <div className="menu-footer-menu-container">
                                    <ul id="menu-footer-menu" className="menu">
                                        <li id="menu-item-109" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-109"><a target="_blank" rel="noopener" href="https://prosperitystockreport.com" data-hovb="prosperitystockreport.com">prosperitystockreport.com</a></li>
                                        <li id="menu-item-110" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-110"><a target="_blank" rel="noopener" href="https://cryptostockindex.com" data-hovb="cryptostockindex.com">cryptostockindex.com</a></li>
                                        <li id="menu-item-111" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-111"><a target="_blank" rel="noopener" href="https://energymetalsindex.com" data-hovb="energymetalsindex.com">energymetalsindex.com</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="nav_menu-4" className="footer-legal widget widget_nav_menu">
                            <div className="widget-helper">
                                <div className="menu-legals-menu-container">
                                    <ul id="menu-legals-menu" className="menu">
                                        <li id="menu-item-113" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-113"><a href={pU+"/privacy-policy"} data-hovb="PRIVACY POLICY">PRIVACY POLICY</a></li>
                                        <li id="menu-item-114" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-114"><a href={pU+"/disclaimer"} data-hovb="DISCLAIMER">DISCLAIMER</a></li>
                                        <li id="menu-item-112" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-112"><a href={pU+"/terms-of-use"} data-hovb="TERMS OF USE">TERMS OF USE</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;