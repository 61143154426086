import Home from './Pages/Home';
import Lsp from './Pages/Lsp';
import Tcs from './Pages/Tcs';
import Indexes from './Pages/Indexes';
import Sectors from './Pages/Sectors';
import Privacy from './Pages/Privacy';
import Disclaimer from './Pages/Disclaimer';
import Terms from './Pages/Terms';
import Stock from './Pages/Stock';
import NotFound from './Pages/NotFound';
import Test from './Pages/Test';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/largest-short-positions">
            <Lsp />
          </Route>
          <Route path="/top-covered-shorts">
            <Tcs />
          </Route>
          <Route path="/indexes">
            <Indexes />
          </Route>
          <Route path="/sectors">
            <Sectors />
          </Route>
          <Route path="/stock/:sid">
            <Stock />
          </Route>
          <Route path="/privacy-policy">
            <Privacy />
          </Route>
          <Route path="/disclaimer">
            <Disclaimer />
          </Route>
          <Route path="/terms-of-use">
            <Terms />
          </Route>
          <Route path="/test">
            <Test />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;