import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import secJson from '../Cache/sec.json';
import { useEffect } from "react";

function Sectors() {

    const pU = process.env.PUBLIC_URL;
    const sectorsArr = [];
    sectorsArr.push({
                fullname    : 'Energy', 
                symbol      : 'energy',  
                desc        : 'Renewable Fuels, Integrated Oil & Gas, Oil & Gas Transport', 
                color       : '#2ecabd'
            });
    sectorsArr.push({
            fullname    : 'Financials', 
            symbol      : 'financials',  
            desc        : 'Banks, Diversified Investment Services, Property & Casualty Ins', 
            color       : '#ff5252'
        });
    sectorsArr.push({
            fullname    : 'Financials', 
            symbol      : 'materials',  
            desc        : 'Chemicals Diversified, Aluminium, Construction Materials', 
            color       : '#000000'
        });
    sectorsArr.push({
            fullname    : 'Healthcare', 
            symbol      : 'healthcare',  
            desc        : 'Pharmaceuticals, Biotechnology & Medical Research', 
            color       : '#ff9935'
        });
    sectorsArr.push({
            fullname    : 'Industrials', 
            symbol      : 'industrials',  
            desc        : 'Industrial Machinery & Equipment, Constuction & Engineering', 
            color       : '#b852ff'
        });
    sectorsArr.push({
            fullname    : 'Information Tech.', 
            symbol      : 'it',  
            desc        : 'Semicontuctors, Computer Hardware, IT Services & Consulting', 
            color       : '#ffe052'
        });
    sectorsArr.push({
            fullname    : 'Consumer Disc.', 
            symbol      : 'consumerdiscretionary',  
            desc        : 'Auto & Truck Manufacturers, Homebuilding, Cons Electronics', 
            color       : '#ff52c0'
        });
    sectorsArr.push({
            fullname    : 'Telecom', 
            symbol      : 'telecom',  
            desc        : 'Integrated Telecoms, Wireless Telecoms', 
            color       : '#52a7ff'
        });
    sectorsArr.push({
            fullname    : 'Consumer Staples', 
            symbol      : 'consumerstaples',  
            desc        : 'Brewers, Personal services, Food Distn & Convenience Stores', 
            color       : '#44ca2e'
        });
    sectorsArr.push({
            fullname    : 'Utilities', 
            symbol      : 'utilities',  
            desc        : 'Electric, Gas, Water', 
            color       : '#442eca'
        });  

    useEffect(() => {

        document.title = 'Sectors - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Sectors - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/sectors';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/sectors');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-sectors page-template-template-sectors-php page page-id-73 with-sidebar';

        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.text = 'var pName = "sectors";';
        let sectorsColors = {};
        sectorsArr.forEach(function(entry) {
            
            script1.text += 'var '+entry.symbol+'SectorsGraphDataX = ['+secJson.graph[entry.symbol].map(function(value) {
                return value.dateUNIX;
            }).reverse().toString()+'];'
            
            script1.text += 'var '+entry.symbol+'SectorsGraphDataY = ['+secJson.graph[entry.symbol].map(function(value) {
                return value.val;
            }).reverse().toString()+'];'

            sectorsColors[entry.symbol] = [entry.color, entry.fullname];
        });
        script1.text += 'var sectorsColors = ' + JSON.stringify(sectorsColors) + ';';
        document.body.appendChild(script1);
        
        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="sectorsextra">
                <div id="container">
                    <div id="page-wrap">
                        <noscript>
                            <div id="js-disabled">
                                Javascript is required for our website's full capabilities!				
                            </div>
                        </noscript>
                        <Header menuitem='sec'/>
                        <div id="content">
                            <div id="inner-content" className="wrap">
                                <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                    
                                    <article id="post-73" className="cf post-73 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                        <section className="entry-content cf" itemProp="articleBody">
                                            <div className="content-wrap">
                                                <h1 className="page-title">Canadian industry & sector short analysis</h1>
                                                <h2 className="page-subtitle">Data analysis provided by:<br />Harris Market Consulting.</h2>
                                                <p>ShortData presents a sector focused analysis on how short positions are changing within the various sectors that comprise the TSX Composite Index. The data is meant to provide an overall sentiment on whether investors are bullish or bearish on Canada’s various industries.</p>
                                                <div id="sectors-data" className="page-data">
                                                    <div className="inner-page-data">
                                                        <div className="data-controls no-pattern no-table">
                                                            <div className="left-data-controls">
                                                                <button className="option sectors-performance active" data-owns="sectors-performance-tab" data-hovb="PERFORMANCE">PERFORMANCE</button>
                                                                <button className="option sectors-charts" data-owns="sectors-charts-tab" data-hovb="CHARTS">CHARTS</button>	
                                                            </div>
                                                        </div>
                                                        <div id="sectors-performance-tab" className="sectors-tab data-tab active">
                                                            <div className="up-down-legend">
                                                                <div className="legend-up"><i className="pre-dot icon icon-circle up"></i><span>Short Sector Decrease</span></div>
                                                                <div className="legend-down"><i className="pre-dot icon icon-circle down"></i><span>Short Sector Increase</span></div>
                                                            </div>
                                                            <div className="sectors-performance-enum">
                                                            {
                                                                Object.keys(sectorsArr).map((stemp, index) =>
                                                                    <div key={'a'+index} className="sector">
                                                                        <div className={secJson.performance[sectorsArr[stemp].symbol].color == 'green' ? 'value up' : 'value down'}>{
                                                                        secJson.performance[sectorsArr[stemp].symbol].per >= 0
                                                                        ? '+'+secJson.performance[sectorsArr[stemp].symbol].per
                                                                        : secJson.performance[sectorsArr[stemp].symbol].per
                                                                        }<span className="percentage">%</span></div>
                                                                        <div className="texts">
                                                                            <div className="name">{sectorsArr[stemp].fullname}:</div>
                                                                            <div className="description">{sectorsArr[stemp].desc}</div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                            </div>
                                                            <div id="sectors-performance-table" className="responsive-table">
                                                                <div className="table-head">
                                                                    <div className="table-cell"><span className="col-content">INDUSTRY & SECTORS</span></div>
                                                                    <div className="table-cell"><span className="col-content">{secJson.dataHeadingTo}</span></div>
                                                                    <div className="table-cell"><span className="col-content">{secJson.dataHeadingFrom}</span></div>
                                                                    <div className="table-cell"><span className="col-content">NET CHANGE</span></div>
                                                                    <div className="table-cell"><span className="col-content">% CHANGE</span></div>
                                                                </div>
                                                                <div className="table-body">
                                                                {
                                                                Object.keys(sectorsArr).map((stemp, index) =>
                                                                    <div key={'b'+index} className="row">
                                                                        <div className="table-cell"><span className="col-content">{sectorsArr[stemp].fullname}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{secJson.performance[sectorsArr[stemp].symbol].to.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{secJson.performance[sectorsArr[stemp].symbol].from.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className="col-content">{secJson.performance[sectorsArr[stemp].symbol].chg.toLocaleString('en-US')}</span></div>
                                                                        <div className="table-cell"><span className={secJson.performance[sectorsArr[stemp].symbol].color == 'green' ? 'col-content up' : 'col-content down'}>{
                                                                        secJson.performance[sectorsArr[stemp].symbol].per >= 0
                                                                        ? '+'+secJson.performance[sectorsArr[stemp].symbol].per
                                                                        : secJson.performance[sectorsArr[stemp].symbol].per
                                                                        }</span></div>
                                                                    </div>
                                                                    )
                                                                }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="sectors-charts-tab" className="sectors-tab data-tab">
                                                            <div className="data-controls graph-controls  no-pattern no-table no-sep">
                                                                <div className="right-data-controls pseudo-select">
                                                                    <button className="option current">
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST YEAR</div>
                                                                    </button>
                                                                    <button className="option option1 option-limted" data-range="6">
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST 3 MONTHS</div>
                                                                    </button>
                                                                    <button className="option option2 option-limted" data-range="12">
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST 6 MONTHS</div>
                                                                    </button>
                                                                    <button className="option option3 option-limted active" data-range="24">
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST YEAR</div>
                                                                    </button>
                                                                    <button className="option option4 option-full" data-range="999999999">
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">ALL</div>
                                                                    </button>
                                                                </div>
                                                                <div className="hoverinfo">&nbsp;</div>
                                                            </div>
                                                            <div id="sectors-graph-container" className="content-graph-container graph-mobile-scroll">
                                                                <div id="sectors-graph" className="content-graph ax-loading"></div>
                                                            </div>
                                                            <div className="color-legend">
                                                            {
                                                                Object.keys(sectorsArr).map((stemp, index) =>
                                                                <div key={'c'+index} className="color-legend-item"><i className="icon icon-circle" style={{"color":sectorsArr[stemp].color}}></i><span>{sectorsArr[stemp].fullname}</span></div>
                                                                )
                                                            }
                                                            </div>
                                                            <div className="tables-legend">SHORT SECTOR ANALYSIS PROVIDED BY HARRIS MARKET CONSULTING BASED ON REPORTED DATA TO IRROC BY EACH INDIVIDUAL MARKETPLACE WHERE TRADES WERE MARKED AS SHORT SALES. SHORT POSITIONS AS OF {secJson.dataHeadingTo} BASED ON AGGREGATE CHANGES FROM 01 Jan 2015 REPORTING.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </article>
                                </main>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <div id="overlay-for-mobile-menu"></div>
                </div>
            </div>
        </>
    );
}

export default Sectors;