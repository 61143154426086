function StockNotFound() {

    return (
        <>
            <article id="post-not-found" className="hentry cf">
                <section className="entry-content">
                    <div className="content-wrap">
                        <h1><span className="hash">#</span>404 <span className="pnf">Page not found</span></h1>
                        <p className="additional-info">No worries! If you got lost, you can use the Search Option or read the links below for some inspiration.</p>
                        <div className="separator line blue"></div>
                    </div>
                </section>
            </article>
        </>
    );
}

export default StockNotFound;