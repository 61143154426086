import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import indJson from '../Cache/ind.json';
import { useEffect, useState } from "react";


function Indexes() {

    const pU = process.env.PUBLIC_URL;
    const [dataHeadingTo, setDataHeadingTo] = useState('');

    useEffect(() => {

        document.title = 'Indexes - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Indexes - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/indexes';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/indexes');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-indexes page-template-template-indexes-php page page-id-67 with-sidebar';
        
        setDataHeadingTo(indJson.dataHeadingTo);

        const script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.text = 'var csiDataX = ['+indJson.csi.map(function(value) {
            return value.dateUNIX;
        }).reverse().toString()+'];';
        script1.text += 'var csiDataY = ['+indJson.csi.map(function(value) {
            return value.val;
        }).reverse().toString()+'];';
        script1.text += 'var csi60DataX = ['+indJson.csiSixty.map(function(value) {
            return value.dateUNIX;
          }).reverse().toString()+'];'
        script1.text += 'var csi60DataY = ['+indJson.csiSixty.map(function(value) {
            return value.val;
          }).reverse().toString()+'];';
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script1);
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="indexesextra">
                <div id="container">
                    <div id="page-wrap">
                        <noscript>
                            <div id="js-disabled">
                                Javascript is required for our website's full capabilities!             
                            </div>
                        </noscript>
                        <Header menuitem='ind'/>
                        <div id="content">
                            <div id="inner-content" className="wrap">
                                <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                    
                                    <article id="post-67" className="cf post-67 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                        <section className="entry-content cf" itemProp="articleBody">
                                            <div className="content-wrap">
                                                <div id="indexes-csi-graph-section" className="indexes-section">
                                                    <div className="data-controls graph-controls no-pattern no-table no-sep">
                                                        <div className="right-data-controls pseudo-select">
                                                            <button className="option current">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST YEAR</div>
                                                            </button>
                                                            <button className="option option1 option-limted" data-range="6">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST 3 MONTHS</div>
                                                            </button>
                                                            <button className="option option2 option-limted" data-range="12">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST 6 MONTHS</div>
                                                            </button>
                                                            <button className="option option3 option-limted active" data-range="24">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST YEAR</div>
                                                            </button>
                                                            <button className="option option4 option-full" data-range="999999999">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">ALL</div>
                                                            </button>
                                                        </div>
                                                        <div className="hoverinfo">S&P/TSX COMPOSITE SHORT INDEX</div>
                                                    </div>
                                                    <div id="indexes-csi-graph-container" className="content-graph-container graph-mobile-scroll">
                                                        <div id="indexes-csi-graph" className="content-graph ax-loading"></div>
                                                    </div>
                                                    <div className="tables-legend">S&P/TSX COMPOSITE SHORT INDEX PROVIDED BY HARRIS MARKET CONSULTING BASED ON REPORTED DATA TO IRROC BY EACH INDIVIDUAL MARKETPLACE WHERE TRADES WERE MARKED AS SHORT SALES. SHORT MARKET INDEX CONSIDERS 01 Jan 2015 AS THE BASE DATE FOR THE INDEX AND USES SHORT TOTAL VALUE OF THE COMPANIES AS COMMON DIVISOR. SHORT VALUE INDEX START VALUE IS 10,000. SHORT POSITIONS AS OF {dataHeadingTo} BASED ON AGGREGATE CHANGES FROM 01 Jan 2015 REPORTING.</div>
                                                </div>
                                                <div id="indexes-csi60-graph-section" className="indexes-section">
                                                    <div className="data-controls graph-controls  no-pattern no-table no-sep">
                                                        <div className="right-data-controls pseudo-select">
                                                            <button className="option current">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST YEAR</div>
                                                            </button>
                                                            <button className="option option1 option-limted" data-range="6">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST 3 MONTHS</div>
                                                            </button>
                                                            <button className="option option2 option-limted" data-range="12">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST 6 MONTHS</div>
                                                            </button>
                                                            <button className="option option3 option-limted active" data-range="24">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">LAST YEAR</div>
                                                            </button>
                                                            <button className="option option4 option-full" data-range="999999999">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">ALL</div>
                                                            </button>
                                                        </div>
                                                        <div className="hoverinfo">S&P/TSX 60 COMPOSITE SHORT INDEX</div>
                                                    </div>
                                                    <div id="indexes-csi60-graph-container" className="content-graph-container graph-mobile-scroll">
                                                        <div id="indexes-csi60-graph" className="content-graph ax-loading"></div>
                                                    </div>
                                                    <div className="tables-legend">S&P/TSX 60 COMPOSITE SHORT INDEX PROVIDED BY HARRIS MARKET CONSULTING BASED ON REPORTED DATA TO IRROC BY EACH INDIVIDUAL MARKETPLACE WHERE TRADES WERE MARKED AS SHORT SALES. SHORT MARKET INDEX CONSIDERS 01 Jan 2015 AS THE BASE DATE FOR THE INDEX AND USES SHORT TOTAL VALUE OF THE COMPANIES AS COMMON DIVISOR. SHORT VALUE INDEX START VALUE IS 10,000. SHORT POSITIONS AS OF {dataHeadingTo} BASED ON AGGREGATE CHANGES FROM 01 Jan 2015 REPORTING.</div>
                                                </div>
                                            </div>
                                        </section>
                                    </article>
                                </main>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <div id="overlay-for-mobile-menu"></div>
                </div>
            </div>
        </>
    );
}

export default Indexes;