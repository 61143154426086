import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import StockNotFound from './StockNotFound';
import { useEffect, useState } from "react";
import { useParams } from 'react-router';

function Stock() {

    const pU = process.env.PUBLIC_URL;

    const [notFoundFlag, setNotFoundFlag] = useState(false);
    const [dataObj, setDataObj] = useState({});
    const [loading, setLoading] = useState(true);
    const { sid } = useParams();
    

    /* Check param */
    let cSym = '';
    if(typeof sid !== 'undefined' ) cSym = sid.replace(/\s/g, "");
    cSym = cSym.substr(0, 10);

    useEffect(() => {

        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        const script1 = document.createElement('script');
        const script2 = document.createElement('script');

        
        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-stock page-template-template-stock-php page page-id-75 with-sidebar';

        fetch(pU+'/feed/data.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: "view=getd&sym="+cSym                    
        })
        .then(res => res.text())
        .then(data => {
            let dataObjTemp = JSON.parse(data);
            if(dataObjTemp.companyName == '') {
                setNotFoundFlag(true);
                document.getElementsByTagName('body')[0].className = 'error404 with-sidebar';
                document.title = 'Page not found - Shortdata.ca';
                document.querySelector('meta[property="og:title"]').setAttribute("content", 'Page not found - Shortdata.ca');
            }
            else {
                setDataObj(JSON.parse(data));
                document.title = dataObjTemp.companyName + ' - Shortdata.ca';
                document.querySelector('meta[property="og:title"]').setAttribute("content", dataObjTemp.companyName + ' - Shortdata.ca');
                document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/stock/'+dataObjTemp.symbol;
                document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/stock/'+dataObjTemp.symbol);
            }
            setLoading(false);

            script1.type = 'text/javascript';
            script1.text = 'var shortVolSummaryX = ['+dataObjTemp.hshortVolSummary.map(function(value) {
                return value.dateUnix;
            }).reverse().toString()+'];';
            script1.text += 'var shortVolSummaryRV = ['+dataObjTemp.hshortVolSummary.map(function(value) {
                return value.regVol;
            }).reverse().toString()+'];';
            script1.text += 'var shortVolSummarySV = ['+dataObjTemp.hshortVolSummary.map(function(value) {
                return value.shortVol;
            }).reverse().toString()+'];';
            script1.text += 'var shortTradeVolX = ['+dataObjTemp.hshortTradeVolArr.map(function(value) {
                return value.dateUnix;
            }).reverse().toString()+'];';
            script1.text += 'var shortTradeVolY = ['+dataObjTemp.hshortTradeVolArr.map(function(value) {
                return value.shortTradeNum;
            }).reverse().toString()+'];';
            script1.text += 'var shortTradeValX = ['+dataObjTemp.hshortTradeValArr.map(function(value) {
                return value.dateUnix;
            }).reverse().toString()+'];';
            script1.text += 'var shortTradeValY = ['+dataObjTemp.hshortTradeValArr.map(function(value) {
                return value.shortTradeNum;
            }).reverse().toString()+'];';
            let tempObj = {};
            tempObj['stock-short-vol-graph-section'] = dataObjTemp['stock-short-vol-graph-section'];
            tempObj['stock-short-trade-graph-section'] = dataObjTemp['stock-short-trade-graph-section'];
            tempObj['stock-short-trade-dollar-graph-section'] = dataObjTemp['stock-short-trade-dollar-graph-section'];
            script1.text += 'var initRange = ' + JSON.stringify(tempObj) + ';';
            document.body.appendChild(script1);

            script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
            script2.async = false;
            document.body.appendChild(script2);
        });

        
        return () => {
            if(document.body.contains(script1)) {
                document.body.removeChild(script1);
            }
            if(document.body.contains(script2)) {
                document.body.removeChild(script2);
            }            
        }

    }, []);

    return (
        <>  
            <div id="companyextra">
                <div id="container">
                    <div id="page-wrap">
                        <noscript>
                            <div id="js-disabled">
                                Javascript is required for our website's full capabilities!				
                            </div>
                        </noscript>
                        <Header />
                        <div id="content">
                            <div id="inner-content" className="wrap">
                                <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                {
                                    notFoundFlag && <StockNotFound />
                                }
                                {
                                    !notFoundFlag &&
                                    <article id="post-75" className="cf post-75 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                        <section className="entry-content cf" itemProp="articleBody">
                                            <div className="content-wrap">
                                                <div id="stock-short-vol-graph-section" className="stock-section">
                                                    { !loading &&
                                                    <div className="data-controls graph-controls  no-pattern no-table no-sep" >
                                                        {
                                                        <div className={Object.keys(dataObj.rezOne).length > 0 ? "right-data-controls pseudo-select" : "right-data-controls"}>
                                                            <button className="option current">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">{Object.keys(dataObj.rezOne).length > 0 ? "LAST YEAR" : "ALL"}</div>
                                                            </button>
                                                            {
                                                                Object.keys(dataObj.rezOne).map((stemp, index) => 
                                                                    <button key={'rezOne'+index} className={stemp=='YEAR'?"option option-limted active":"option option-limted"} data-range={dataObj.rezOne[stemp]}>
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST {stemp}</div>
                                                                    </button>
                                                                )

                                                            }
                                                            {
                                                                Object.keys(dataObj.rezOne).length > 0 && 
                                                                <button className="option option-full" data-range="9999999">
                                                                    <div className="description">SHOW<span>ING</span></div>
                                                                    <div className="sub-description">ALL</div>
                                                                </button> 
                                                            }                                                            
			            			    		        </div>
                                                        }                                                        
                                                        <div className="hoverinfo"><span>SHORT VOLUME SUMMARY: </span>{dataObj.exchange+':'+dataObj.symbol} 15 day</div>
                                                    </div>
                                                    }
                                                    <div id="stock-short-vol-container" className="content-graph-container graph-mobile-scroll">
                                                        <div id="stock-short-vol-graph" className="content-graph ax-loading"></div>
                                                    </div>
                                                    { !loading &&
                                                    <div className="tables-legend">Our chart above displays an aggregate short trade summary over each 15 day reporting period on {dataObj.symbol}. Here you can determine if short volume on {dataObj.symbol} is increasing or decreasing. Continue below to see the total # of short trades.</div>
                                                    }
                                                </div>
                                                <div id="stock-short-trade-graph-section" className="stock-section">
                                                    { !loading &&
                                                    <div className="data-controls graph-controls  no-pattern no-table no-sep">
                                                        {
                                                        <div className={Object.keys(dataObj.rezTwo).length > 0 ? "right-data-controls pseudo-select" : "right-data-controls"}>
                                                            <button className="option current">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">{Object.keys(dataObj.rezTwo).length > 0 ? "LAST YEAR" : "ALL"}</div>
                                                            </button>
                                                            {
                                                                Object.keys(dataObj.rezTwo).map((stemp, index) => 
                                                                    <button key={'rezTwo'+index} className={stemp=='YEAR'?"option option-limted active":"option option-limted"} data-range={dataObj.rezTwo[stemp]}>
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST {stemp}</div>
                                                                    </button>
                                                                )

                                                            }
                                                            {
                                                                Object.keys(dataObj.rezTwo).length > 0 && 
                                                                <button className="option option-full" data-range="9999999">
                                                                    <div className="description">SHOW<span>ING</span></div>
                                                                    <div className="sub-description">ALL</div>
                                                                </button> 
                                                            }                                                            
			            			    		        </div>
                                                        }
                                                        <div className="hoverinfo"><span>SHORT TRADE ANALYSIS: </span>{dataObj.exchange+':'+dataObj.symbol} 15 day</div>
                                                    </div>
                                                    }
                                                    <div id="stock-short-trade-container" className="content-graph-container graph-mobile-scroll">
                                                        <div id="stock-short-trade-graph" className="content-graph ax-loading"></div>
                                                    </div>
                                                    { !loading &&
                                                    <div className="tables-legend">Are the amount of short trades increasing or decreasing? Our chart above shows the overall trend.</div>
                                                    }
                                                </div>
                                                <div id="stock-short-trade-dollar-graph-section" className="stock-section">
                                                    { !loading &&
                                                    <div className="data-controls graph-controls  no-pattern no-table no-sep">
                                                        {
                                                        <div className={Object.keys(dataObj.rezThree).length > 0 ? "right-data-controls pseudo-select" : "right-data-controls"}>
                                                            <button className="option current">
                                                                <div className="description">SHOW<span>ING</span></div>
                                                                <div className="sub-description">{Object.keys(dataObj.rezThree).length > 0 ? "LAST YEAR" : "ALL"}</div>
                                                            </button>
                                                            {
                                                                Object.keys(dataObj.rezThree).map((stemp, index) => 
                                                                    <button key={'rezThree'+index} className={stemp=='YEAR'?"option option-limted active":"option option-limted"} data-range={dataObj.rezThree[stemp]}>
                                                                        <div className="description">SHOW<span>ING</span></div>
                                                                        <div className="sub-description">LAST {stemp}</div>
                                                                    </button>
                                                                )

                                                            }
                                                            {
                                                                Object.keys(dataObj.rezThree).length > 0 && 
                                                                <button className="option option-full" data-range="9999999">
                                                                    <div className="description">SHOW<span>ING</span></div>
                                                                    <div className="sub-description">ALL</div>
                                                                </button> 
                                                            }                                                            
			            			    		        </div>
                                                        }
                                                        <div className="hoverinfo"><span>SHORT TRADE DOLLAR VALUE: </span>{dataObj.exchange+':'+dataObj.symbol} 15 day</div>
                                                    </div>
                                                    }
                                                    <div id="stock-short-trade-dollar-container" className="content-graph-container graph-mobile-scroll">
                                                        <div id="stock-short-trade-dollar-graph" className="content-graph ax-loading"></div>
                                                    </div>
                                                    { !loading &&
                                                    <div className="tables-legend">Our chart above displays the aggregate dollar value of all trades over a 15 day period on {dataObj.symbol}. Discover the dollar trend on short trades and determine if the shorts are increasing their bet, or starting to cover their short positions.</div>
                                                    }
                                                </div>
                                                { !loading &&
                                                <div id="stock-histo-section">
                                                    <div id="stock-table" className="data-table">
                                                        <div className="inner-data-table">
                                                            <div className="data-controls">{
                                                                !loading && 
                                                                <div className="left-data-controls">
                                                                    <button className="option active">Historical Short Volume Data for <span>{dataObj.symbol}</span></button>	
                                                                </div>
                                                                }
                                                                <div className="right-data-controls pseudo-select">
                                                                    <button className="option current" data-owns={"table-"+dataObj.historicalTab[0]}>
                                                                        <div className="description">TIME FRAME</div>
                                                                        <div className="sub-description">{'JAN-DEC '+dataObj.historicalTab[0]}</div>   
                                                                    </button>
                                                                    <button className={"option active"} data-owns={"stock-"+dataObj.historicalTab[0]+"-tab"}>
                                                                        <div className="description">TIME FRAME</div>
                                                                        <div className="sub-description">{'JAN-DEC '+dataObj.historicalTab[0]}</div>
                                                                    </button>
                                                                {
                                                                    Object.values(dataObj.historicalTab).map((year, key) => 
                                                                        key > 0 &&
                                                                            <button key={'year'+year} className={"option"} data-owns={"stock-"+year+"-tab"}>
                                                                                <div className="description">TIME FRAME</div>
                                                                                <div className="sub-description">{'JAN-DEC '+year}</div>
                                                                            </button>
                                                                    )
                                                                }    
                                                                </div>
                                                            </div>
                                                            {   
                                                                Object.values(dataObj.historicalTab).map((year, key) => 
                                                                    <div key={'historical-'+year} id={"stock-"+year+"-tab"} className={key==0?"stock-tab active":"stock-tab"}>
                                                                        <div id={"stock-"+year+"-table"} className="data-table-wrapper data-table">
                                                                            <div className="responsive-table stock-table">
                                                                                <div className="table-head">
                                                                                    <div className="table-cell"><span className="col-content">DATE</span></div>
                                                                                    <div className="table-cell"><span className="col-content">S. SALE TRADES</span></div>
                                                                                    <div className="table-cell"><span className="col-content">% TOTAL TRADES</span></div>
                                                                                    <div className="table-cell"><span className="col-content">S. TRADED VOL.</span></div>
                                                                                    <div className="table-cell"><span className="col-content">% TOT. VOL.</span></div>
                                                                                    <div className="table-cell"><span className="col-content">S. TRADED VAL.</span></div>
                                                                                    <div className="table-cell"><span className="col-content">% TOT. VAL.</span></div>
                                                                                </div>
                                                                                <div className="table-body">
                                                                                {
                                                                                Object.keys(dataObj.historicalTable[year]).map((k) =>
                                                                                    <div key={'year-'+k} className="row">
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][0]}</span></div>
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][1]}</span></div>
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][2]}</span></div>
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][3]}</span></div>
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][4]}</span></div>
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][5]}</span></div>
                                                                                        <div className="table-cell"><span className="col-content">{dataObj.historicalTable[year][k][6]}</span></div>
                                                                                    </div>
                                                                                )
                                                                                }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </section>
                                    </article>
                                }    
                                </main>
                                <Sidebar />
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <div id="overlay-for-mobile-menu"></div>
                </div>
            </div>
        </>
    );
}

export default Stock;