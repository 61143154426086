import Header from '../Sections/Header';
import Footer from '../Sections/Footer';
import Sidebar from '../Sections/Sidebar';
import { useEffect } from "react";

function Privacy() {

    const pU = process.env.PUBLIC_URL;

    useEffect(() => {

        document.title = 'Privacy Policy - Shortdata.ca';
        document.querySelector('meta[property="og:title"]').setAttribute("content", 'Privacy Policy - Shortdata.ca');
        document.querySelector('link[rel="canonical"]').href = 'https://shortdata.ca/privacy-policy';
        document.querySelector('meta[property="og:url"]').setAttribute("content", 'https://shortdata.ca/privacy-policy');
        document.querySelector('meta[property="og:type"]').setAttribute("content", 'article');

        document.getElementsByTagName('body')[0].className = 'page-template page-template-template-legals page-template-template-legals-php page page-id-71 with-sidebar';
        
        const script2 = document.createElement('script');
        script2.src = pU + "/wp-content/themes/shortdata/library/js/main.js";
        script2.async = false;
        document.body.appendChild(script2);

        return () => {
            document.body.removeChild(script2);
        }

    }, []);

    return (
        <>
            <div id="container">
                <div id="page-wrap">
                    <noscript>
                        <div id="js-disabled">
                            Javascript is required for our website's full capabilities!				
                        </div>
                    </noscript>
                    <Header />
                    <div id="content">
                        <div id="inner-content" className="wrap">
                            <main id="main" role="main" itemScope itemProp="mainContentOfPage" itemType="http://schema.org/Blog">
                                <article id="post-71" className="cf post-71 page type-page status-publish hentry" role="article" itemScope itemType="http://schema.org/BlogPosting">
                                    <header className="article-header">
                                        <h1 className="page-title" itemProp="headline">Privacy Policy</h1>
                                    </header>
                                    <section className="entry-content cf" itemProp="articleBody">
                                        <h2 style={{"clear":"both"}}>Email Policy</h2>
                                        <p className="byline">We hate spam, and we do not contribute to the furtherance of spam by selling, renting, loaning or in any way distributing your contact information to others. We don’t inundate you with a bunch of unwanted emails when you provide us with your email address. We will, from time to time, let you know about features or ways to utilize shortdata.ca, but if you ever don’t want to hear from us anymore, simply click ‘unsubscribe’ in any of our email alerts and you will be instantly unsubscribed as per CanSpam Act regulations.</p>
                                        <h2>Disclaimer</h2>
                                        <p className="byline">The information and recommendations made available here (“Information”) by shortdata.ca and/or all affiliates is for informational purposes only and not to be used or construed as an offer to sell or a solicitation of an offer to buy any services or securities. You further agree that Harris Market Consulting will not be liable for any losses or liabilities that may be occasioned as a result of the information or commentary provided on our website, in our reports or by email. By accessing the site and receiving our emails, you accept and agree to be bound by and comply with the terms and conditions set out herein. If you do not accept and agree to the terms, you should not use this site or accept any of our emails.</p>
                                        <p className="byline">Harris Market Consulting (shortdata.ca) is not registered as an adviser under the securities legislation of any jurisdiction of Canada and provides the Information pursuant to an exemption from the registration requirements that is available in respect of generic advice. The recommendations contained on the site and in this email are not tailored to the needs of particular persons and may not be appropriate for you depending on your financial position or investment goals or needs. You should apply your own judgment in making any use of the Information, especially as the basis for any investment decision. Prior to making any investment decision, we recommend that you seek outside advice from a qualified and registered investment advisor.</p>
                                        <p className="byline">In no event will shortdata.ca be responsible or liable to you or any other party for any damages of any kind arising out of or relating to the use of, misuse of or inability to use this site and its email messages. The Information is directed only at a person’s resident in Canada. Nothing in this site shall constitute an offer or solicitation to anyone in the United States of America or any jurisdiction where such offer or solicitation is not authorized or to any person to whom it is unlawful to make such a solicitation. If you choose to access this site or email from outside of Canada, you acknowledge that the Information is intended for use by persons resident in Canada only.</p>
                                        <p className="byline">This is not an investment advisory, and should not be used to make investment decisions. Information in shortdata.ca is often opinionated and should be considered for information purposes only. No stock exchange anywhere has approved or disapproved of the information contained herein. There is no express or implied solicitation to buy or sell securities. The writers and editors of shortdata.ca may have positions in the stocks discussed above and may trade in the stocks mentioned. Don’t consider buying or selling any stock without conducting your own due diligence.</p>
                                        <p><strong>© 2021 Copyright Harris Market Consulting.<br />
                                            </strong>
                                        </p>
                                    </section>
                                </article>
                            </main>
                            <Sidebar />
                        </div>
                    </div>
                </div>
                <Footer />
                <div id="overlay-for-mobile-menu"></div>
            </div>
        </>
    );
}

export default Privacy;