function Header(props) {

    const pU = process.env.PUBLIC_URL;

    const menuSelItem = props.menuitem;

    let item0 = '';
    let item1 = '';
    let item2 = '';
    let item3 = '';
    let item4 = '';

    switch(menuSelItem) {
        case 'home':
            item0 = ' current-menu-item current_page_item';
        break;

        case 'lsp':
            item1 = ' current-menu-item current_page_item';
        break;

        case 'tcs':
            item2 = ' current-menu-item current_page_item';
        break;

        case 'ind':
            item3 = ' current-menu-item current_page_item';
        break;

        case 'sec':
            item4 = ' current-menu-item current_page_item';
        break;
    }


    return (
        <>
            <header className="header" role="banner" itemScope itemType="http://schema.org/WPHeader">
                <div id="menubar">
                    <div id="inner-menubar" className="wrap">
                        <div id="logo" itemScope itemType="http://schema.org/Organization"><a href={pU+"/"} rel="nofollow">Shortdata.ca</a></div>
                        <div id="mobile-menu-button-wrapper">
                            <button id="mobile-menu-button" className="c-hamburger c-hamburger--rot">
                            <span></span>
                            </button>
                        </div>
                        <div id="main-menu-wrapper">
                            <div id="menu-scroller" className="overthrow">
                                <div id="mobile-menu-logo"><a href={pU+"/"} rel="nofollow">Shortdata.ca</a></div>
                                <nav id="main-menu" role="navigation" itemScope itemType="http://schema.org/SiteNavigationElement">
                                    <ul id="menu-main-menu" className="nav top-nav">
                                        <li id="menu-item-101" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-home page_item page-item-65 menu-item-101"+item0}><a href={pU+"/"} aria-current="page" data-hovb="Now!">Now!</a></li>
                                        <li id="menu-item-105" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-105"+item1}><a href={pU+"/largest-short-positions"} data-hovb="Largest short positions">Largest short positions</a></li>
                                        <li id="menu-item-108" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-108"+item2}><a href={pU+"/top-covered-shorts"} data-hovb="Top covered shorts">Top covered shorts</a></li>
                                        <li id="menu-item-104" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-104"+item3}><a href={pU+"/indexes"} data-hovb="Indexes">Indexes</a></li>
                                        <li id="menu-item-106" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-106"+item4}><a href={pU+"/sectors"} data-hovb="Sectors">Sectors</a></li>
                                    </ul>
                                </nav>
                                <div className="menubar-search-wrapper">
                                    <div className="menubar-search">
                                        <form role="search" method="get" id="stocksearchform2" className="searchform" onSubmit={()=>{javascript:void(0);}}>
                                            <label htmlFor="s" className="icon icon-search"></label>
                                            <input autoComplete="off" className="searchinput" type="search" id="s" name="s" placeholder="SEARCH STOCK SYMBOL" />
                                            <input type="submit" className="stocksearchformbuttom" value="search" disabled />
                                            <div className="results"></div>
                                        </form>
                                    </div>
                                </div>
                                <div id="mobile-menu-pagenav">
                                    <a href="#inner-footer" id="pagenav-down" className="pagenav-button icon icon-chevron-down2"></a>
                                    <a href="#" id="pagenav-up" className="pagenav-button icon icon-chevron-up"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;